<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-sidebar id="view_subscriber" aria-labelledby="sidebar-no-header-title" width="90%" no-header backdrop style="direction:ltr" right title="اضافة عميل" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>{{lang.view_subscriber}}</span>
                </div>
                <div @click="hide" id="hideViewCus" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
        </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row :style="`margin:auto;width:100%;direction:rtl;text-align:right`">
                <v-col cols="12" :style="`direction:`+lang.dir+`;text-align:`+lang.algin+`;`">
                    <div class="tobbuttom backBlack" style="border:1px solid #bbb !important;background:#ccc !important;padding:1px;width:100%;text-align:center;">
                        <b-button class="mytab1 btn btn-sm btn-success m-2" style="font-weight:600;color:#fff !important;background:#000 !important;border:2px solid #fff !important;font-size:1.1rem !important;width:150px;white-space: nowrap;" @click="resetExpen()" v-b-toggle.add_offer_jobOrder>اضافة امر تشغيل</b-button> 
                        <!-- <b-button style="font-weight:600;color:#fff !important;border:2px solid #fff !important;font-size:1.1rem !important;background:#000 !important;;width:150px;" class="mytab2 btn btn-sm btn-primary m-2" >طلب شراء</b-button>  -->
                    </div>
                </v-col>
            </v-row>
            <v-row :style="`margin:auto;width:100%;direction:rtl;text-align:right`">
                <v-col cols="12" md="12" :style="`direction:`+lang.dir+`;text-align:`+lang.algin+`;`">
                    <v-simple-table>
                        <thead>
                            <tr>
                            <th 
                                v-for="(item,index) in headers" 
                                :key="index"
                                :class="item.class"
                                :style="item.style"
                            >{{ item.text }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in tablerows" :key="index">
                                <td class="text-center">{{ item.customer_contact_value }}</td>
                                <td class="text-center" :style="`background:${item.customer_source_bgColor} !important;color:${item.customer_source_color} !important;`">{{ item[`customer_source_name_${lang.langname}`] }}</td>
                                <td class="text-center" :style="`background:${item.customer_type_bgColor} !important;color:${item.customer_type_color} !important;`">{{ item[`customer_type_name_${lang.langname}`] }}</td>
                                <td class="text-center">{{ item.customer_name }}</td>
                                <td class="text-center">{{ item.campaign_title }}</td>
                                <td class="text-center">{{ item.start_date }}</td>
                                <td class="text-center">{{ item.end_date }}</td>
                                <td class="text-center">{{ item.plate_number }}</td>
                                <td class="text-center">{{ item.brand }}</td>
                                <td class="text-center">{{ item.model }}</td>
                                <td class="text-center">{{ item.car_year }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
                <v-col cols="12" md="6" :style="`direction:`+lang.dir+`;text-align:`+lang.algin+`;`">
                    <div class="backBlack text-center p-1 mb-1">اوامر التشغيل</div>
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th class="backBlack text-center p-1">رقم امر التشغيل</th>
                                <th class="backBlack text-center p-1">تاريخ دخول السيارة</th>
                                <th class="backBlack text-center p-1">الحالة</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in tablerows[0].jobs" :key="index">
                                <td class="text-center">{{ item.cardid }}</td>
                                <td class="text-center">{{ item.received_date.substr(0,10) }}</td>
                                <td class="text-center">{{ item[`status_name${lang.langname}`] }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-col>
                <v-col cols="12" md="6" :style="`direction:`+lang.dir+`;text-align:`+lang.algin+`;`">
                    <div class="backBlack text-center p-1 mb-1">مميزات البرنامج</div>
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th class="backBlack text-center p-1">رمز الصنف</th>
                                <th class="backBlack text-center p-1">الصنف</th>
                                <th class="backBlack text-center p-1">الكمية</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in serviceList" :key="index">
                                <td class="text-center">{{ item.itemcode }}</td>
                                <td class="text-center">{{ item.item_name }}</td>
                                <td class="text-center">{{ $RoundNum(item.qty) }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-col>
                <v-col cols="12" md="6" :style="`direction:`+lang.dir+`;text-align:`+lang.algin+`;`">
                    
                </v-col>
            </v-row>
        </div>
        </template>
        
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>

        <div class="cusModal" v-if="showtable">
            <div class="cusModalCont">
                <div class="cusModalTitle backBlack">{{ table_titel }}
                    <div class="cusModalClose" @click="closeTable()">{{ lang.close }}</div>
                </div>
                <div class="cusModalBody">
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th class="backBlack text-center p-1">الوصف</th>
                                <th class="backBlack text-center p-1" style="width:200px;">المواصفات</th>
                                <th class="backBlack text-center p-1" style="width:80px;">التكلفة</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in campDet" :key="index">
                                <td class="text-center p-1">{{ item.description }}</td>
                                <td class="text-center p-1">{{ item.details }}</td>
                                <td class="text-center p-1">{{ item.cost }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </div>
            </div>
        </div>
        <subscriber-add-order ref="SubscriberAddOrder" />
      </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
import SubscriberAddOrder from './subscriberAddOrder.vue';
export default{
    components: {SubscriberAddOrder},
    data(){
        return{
            id: 0,
            showtable: false,
            table_titel: 'المواد الاعلانية',
            tablerows: [], 
            serviceList: []
        }
    },

    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        brachiesList: function(){
            let t = [{text: this.lang.all_branches, value: 0}]
            for(let i = 0;i<this.branchies.length;i++){
                t.push({text: this.branchies[i][`name_${this.lang.langname}`], value: this.branchies[i].id})
            }
            return t;
        },
        checkItems: function(){
            let t = [];
            console.log(this.serviceList);
            return t;
        },
        ariasList: function(){
            return [
                {text: this.lang.all_regions, value: 1},
                {text: this.lang.exact_city, value: 2},
            ]
        },
        headers: function(){
            let t=[]
        t.push({
              text: "رقم الاتصال",
              align: 'center',
              filterable: true,
              value: 'mobile3',
              sortable: false,
              class: 'pa-0 backBlack text-center',
              style: 'width:90px;'
        })
        t.push({
              text: "مصدر العميل",
              align: 'center',
              filterable: true,
              value: 'mobile3',
              sortable: false,
              class: 'pa-0 backBlack text-center',
              style: 'width:90px;'
        })
        t.push({
              text: "نوع العميل",
              align: 'center',
              filterable: true,
              value: 'mobile3',
              sortable: false,
              class: 'pa-0 backBlack text-center',
              style: 'width:90px;'
        })
        t.push({
              text: "اسم العميل",
              align: 'center',
              filterable: true,
              value: 'mobile3',
              sortable: false,
              class: 'pa-0 backBlack text-center',
              style: ''
        }) 
        t.push({
              text: "اسم البرنامج",
              align: 'center',
              filterable: true,
              value: 'mobile3',
              sortable: false,
              class: 'pa-0 backBlack text-center',
              style: ''
        }) 
        t.push({
              text: "تاريخ البدء",
              align: 'center',
              filterable: true,
              value: 'exp_date',
              sortable: false,
              class: 'pa-0 backBlack text-center',
              style: 'width:90px;'
        })
        t.push({
              text: "تاريخ الانتهاء",
              align: 'center',
              filterable: true,
              value: 'mobile2',
              sortable: false,
              class: 'pa-0 backBlack text-center',
              style: 'width:90px;'
        })
        
        t.push({
              text: "رقم اللوحة",
              align: 'center',
              filterable: true,
              value: 'mobile2',
              sortable: false,
              class: 'pa-0 backBlack text-center',
              style: 'width:90px;'
        })
        t.push({
              text: "الشركة المصنعة",
              align: 'center',
              filterable: true,
              value: 'mobile2',
              sortable: false,
              class: 'pa-0 backBlack text-center',
              style: 'width:90px;'
        })
        t.push({
              text: "نوع السيارة",
              align: 'center',
              filterable: true,
              value: 'mobile2',
              sortable: false,
              class: 'pa-0 backBlack text-center',
              style: 'width:90px;'
        })
        t.push({
              text: "سنة الصنع",
              align: 'center',
              filterable: true,
              value: 'mobile2',
              sortable: false,
              class: 'pa-0 backBlack text-center',
              style: 'width:90px;'
        })
        return t;
      }
    }, 
    created(){
        // this.getBranches();
        // this.getStaticid('inDustrial');
        // this.getStaticid('customerSource');
    },
    methods: {
        resetExpen(){
            this.$refs.SubscriberAddOrder.prog_id = this.id
            this.$refs.SubscriberAddOrder.resetValues(this.serviceList)
        },
        closeTable(){
            this.campDet = [];
            this.showtable = false;
        },
        
        getSubscribe(id){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getSubscriber'); 
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append("data[id]",id) 
            axios.post( 
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data){
                    this.id = response.data.results.data[0].id
                    this.tablerows = response.data.results.data
                    this.getOffers(this.tablerows[0].prog_name ?? -1)
                }
            })
        },
        getOffers(id){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getOffer'); 
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append("data[id]",id) 
            axios.post( 
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data){
                    this.serviceList = response.data.results.data[0].products ?? []
                }
            })
        },
        resetAllValues(){
            this.tablerows = []
        },
        
        getStaticid(id) {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "getStaticOptions");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", id);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                // // console.log("i am here",response.data)
                if(id == 'convertProducts')
                    this.optionsS = response.data.results.data;
                else if(id == 'customerSource')
                    this.sources = response.data.results.data
                else if(id == 'inDustrial')
                    this.inDusts = response.data.results.data
                // // console.log("i am here",this.optionsS);
            });
        },
        
    },
}
</script>
<style scoped>
.hideIcon {
    display:none;
}
.cusModal{
    position: fixed;
    background:#0000001a;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 20000000;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;;
}
.cusModalCont{
    position:relative;
    width:70%;
    height: 70vh;
    border:1px solid #bbb;
    background:#fff;
    box-shadow: 0px 1px 1px 1px #0000001a;
    border-radius: 5px;
}
.cusModalClose{
    position: absolute;
    top:2px;
    left:10px;
    background:red;
    color:#fff;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalCloseL:hover{
    background: darkred;
}
.cusModalTitle{
    padding:5px;
    text-align:center;
    border-color: #bbb !important;
    border-bottom-color: #000 !important;
}
.cusModalBody{
    overflow: auto;
    height:calc(70vh - 50px);
    max-height:calc(70vh - 50px);
    padding:5px;
}
</style>
